<template>
  <v-container id="cloud-control" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 東面捲揚上層_開啟 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 西面捲揚上層_開啟 ( 裝置: 神來牧場 ) 值: 1
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 東西捲揚上層_開啟 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 西面捲網上層_開啟 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 東面捲網上層_開啟 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 北面捲揚上層_關閉 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 西面捲揚下層_關閉 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 東西捲揚上層_關閉 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 東西捲揚上層_關閉 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card cols="12" md="6" elevation="3" class="mx-auto">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h4 mb-1 font-weight-black">
                設定暫存器 - 東西捲揚上層_關閉 ( 裝置: 神來牧場 ) 值: 0
              </v-list-item-title>
              <v-list-item-subtitle
                >2021/10/13 19:11:06 By
                shenlai.dairy@gmail.com</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <!-- 由於 Indoor Map 寫在 index.html，每個 Component 都要建一個 id="myMap" 的元素，否則 console 會有錯誤訊息，更嚴謹的處理方案待研究。 -->
    <div id="myMap" />
  </v-container>
</template>

<script>
export default {
  name: 'CloudControl',
  data () {
    return {
      switch1: true,
      switch2: false,
      switch3: false,
      switch4: true,
      switch5: true,
      switch6: false
    }
  }
}
</script>

<style scoped lang="scss">
#myMap {
  display: none;
}
.v-input__slot > .v-label {
  -webkit-order: 1;
  order: 1;
  font-weight: 800;
}
.v-input__slot > .v-input--selection-controls__input {
  -webkit-order: 2;
  order: 2;
}
.w-100 {
  width: 100% !important;
}
.v-messages {
  margin-bottom: 1em;
}
</style>
